<template>
  <div class="g-box g-main-box">
    <div class="u-main">
      <div class="client-header" :class="{gradient: gradient}">
        <div class="client-header-left">
          <div class="client-header-left-box">
            <span class="left-item">
              <div class="logo"></div>
            </span>
            <span class="left-item" style="margin-left: -10px">
              <span
                v-if="!$route.meta.back && !$route.meta.home"
                @click="$router.go(-1)"
              >
                <i class="iconfont icon-fanhui"></i>
                {{ $t("global.back") }}
              </span>
              <span v-else-if="$route.meta.home" @click="$router.push('/home')">
                <i class="icon el-icon-s-home"></i>
                {{ $t("global.backHome") }}
              </span>
            </span>
          </div>
        </div>
        <div class="client-header-main">
          <img
            v-if="$route.meta.isLite"
            src="./../assets/img/lite-tit.png"
            alt=""
          />
          <template v-else>
            {{ $t(`menu['${$route.meta.title}']`) }}
          </template>
        </div>
        <div class="client-header-right">
          <div class="client-header-right-box">
            <span class="right-item lang" v-if="false">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <span class="lang-item" v-if="$i18n.locale == 'zh'">
                    <!-- <span class="lang-icon"
                      ><img :src="publicPath + '/lang/zh.png'"
                    /></span> -->
                    中文 <i class="el-icon-arrow-down el-icon--right"></i
                  ></span>
                  <span class="lang-item" v-if="$i18n.locale == 'en'">
                    <!-- <span class="lang-icon"
                      ><img :src="publicPath + '/lang/en.png'"
                    /></span> -->
                    English <i class="el-icon-arrow-down el-icon--right"></i
                  ></span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="toggleLang('zh')"
                    :disabled="$i18n.locale == 'zh'"
                  >
                    <!-- <span class="lang-icon"
                      ><img :src="publicPath + '/lang/zh.png'"
                    /></span> -->
                    中文</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="toggleLang('en')"
                    :disabled="$i18n.locale == 'en'"
                  >
                    <!-- <span class="lang-icon"
                      ><img :src="publicPath + '/lang/en.png'"
                    /></span> -->
                    English</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </span>
            <el-dropdown
              v-if="$store.state.user.userName || $store.state.user.phone"
            >
              <span class="right-item el-dropdown-link">
                <span style="display: flex; align-items: center">
                  <span class="avator"><img :src="avator" alt="avator" /></span>
                  <span>{{
                    $store.state.user.userName || $store.state.user.phone
                  }}</span>
                </span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="$store.state.user.userType !== 3"
                  @click.native="handleChangePass()"
                  >{{ $t("global.修改密码") }}</el-dropdown-item
                >
                <el-dropdown-item @click.native="handleLogout()">{{
                  $t("global.退出登录")
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span class="right-item" v-else @click="handleGoLogin">{{
              $t("global.请登录")
            }}</span>
            <span
              class="right-item"
              style="
                border: solid 1px #ffffff;
                padding: 8px 10px;
                border-radius: 4px;
              "
              v-if="$route.name == 'bill-write'"
              @click="$router.push('/bill/audit')"
            >
              账本审核</span
            >
          </div>
        </div>
      </div>
      <div class="u-body">
        <transition name="fade" mode="out-in">
          <router-view ref="tableRef" id="tableRef"></router-view>
        </transition>
      </div>
    </div>
    <my-dialog ref="myDialog" />
  </div>
</template>

<script>
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import api from '@/api'
import getConfig from '@/config'
import tool from '@/tool'
import MyDialog from '@/views/Home/dialog'

export default Vue.extend({
  components: {
    MyDialog
  },
  directives: {},
  filters: {},
  props: {
  },
  data () {
    return {
      gradient: true,
      publicPath: getConfig().pathZeus,
      langLoading: false,
      api: api,
      loadMenu: false,
      expand: true,
      breadList: [],
      local: Vue.local,
      logo: '', // 园区logo
      miniLogo: '', // 园区minilogo
      systemName: '', // 系统名称
      avator: getConfig().pathZeus + '/avator.png'
    }
  },
  computed: {
    getLogo () {
      return this.logo || VueCookie.get('richeninfo-logo') || ''
    },
    getMiniLogo () {
      return this.miniLogo || VueCookie.get('richeninfo-minilogo') || ''
    },
    getSystemName () {
      return this.systemName || VueCookie.get('richeninfo-systemName') || this.defaultSystemName
    }
  },
  watch: {
    $route () {
    }
  },
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {
    document.querySelector('.u-body').addEventListener('scroll', e => {
      var y = e.target.scrollTop
      if (y > 0) {
        this.gradient = false
      } else {
        this.gradient = true
      }
    })

  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {

    handleLogout () {
      tool.clearLocalData()
      this.$store.commit('clearUser', {})
      api.auth.getAuthLogout({}).then(res => { })
      this.$router.replace('/login')
    },
    handleGoLogin () {
      this.$router.push('/login')
    },
    handleChangePass () {
      this.$refs.myDialog.show()
    },
    toggleLang (lang) {
      if (lang === 'zh') {
        localStorage.setItem('locale', 'zh')
        this.$i18n.locale = localStorage.getItem('locale')
        // this.$message({
        //   message: '切换为中文！',
        //   type: 'success'
        // })
        window.location.reload()
      } else if (lang === 'en') {
        localStorage.setItem('locale', 'en')
        this.$i18n.locale = localStorage.getItem('locale')
        // this.$message({
        //   message: 'Switch to English!',
        //   type: 'success'
        // })
        window.location.reload()
      }
    }
  }
})
</script>
<style lang="scss" scoped>
.u-body {
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
<style lang="scss" scoped>
@import "~assets/css/global-variable.scss";
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.g-main-box {
  display: flex;
  width: 100%;
  height: 100%;
  background: #fafafa;
  .u-main {
    display: flex;
    //flex: 1;
    width: 100%;
    flex-direction: column;
    transition: all ease 0.3s;
    &[data-mini] {
      width: 100%;
    }
    .m-header {
    }
    .u-body {
      padding: 64px 30px 0;
      flex: 1;
      background: url("~assets/img/page-bg.png") repeat left top/100% 900px !important;
      background-attachment: fixed;
      box-sizing: border-box;
      position: relative;

      overflow-x: hidden;
      overflow-y: auto;

      .u-bread {
        position: absolute;
        top: 18px;
        left: 20px;
        .u-refresh {
          float: left;
          cursor: pointer;
          color: $fc-theme;
        }
        .u-pos {
          float: left;
          margin-left: 10px;
        }
      }
    }
  }
}

.client-header {
    width: 100%;
    min-width: 1400px;
    background: #0c1245;
    color: #ffffff;
    position: fixed;
    top: 0px;
    display: flex;
    height: 64px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    z-index: 99;
    transition: all 500ms;

    &.gradient {
        background: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0.3) 0%,
                        rgba(5, 7, 15, 0) 100%
        );
    }

    .logo {
        width: 91px;
        height: 32px;
        background: url("~assets/img/logo.png") no-repeat;
        background-size: 100%;
        /* transform: scale(0.8); */
    }
    .lang-icon {
        display: inline-block;
        width: 16px;
        height: 11px;
        img {
            width: 100%;
        }
    }
    .lang {
        border: solid 1px #747474;
        padding: 4px 8px;
        border-radius: 2px;
    }
    .avator {
        width: 32px;
        height: 32px;
        background: #b1e7ff;
        display: inline-block;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 10px;
        img {
            width: 100%;
            height: 100%;
            display: inline-block;
        }
    }
    .client-header-left {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        cursor: pointer;
        font-size: 16px;
        width: 120px;

        .client-header-left-box {
            position: absolute;
            top: 0;
            left: 24px;
            height: 100%;
            display: flex;
            align-items: center;
            white-space: nowrap;

            span.left-item {
                font-size: 14px;
                color: #ffffff;
                margin-left: 30px;
                margin-right: 30px;
                user-select: none;
                cursor: pointer;
                i {
                    line-height: 50px;
                }
                &:first-child {
                    margin-left: 0px;
                }
            }
        }
    }

    .client-header-right {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 20px;
        cursor: pointer;
        font-size: 16px;
        width: 120px;
        position: relative;

        .client-header-right-box {
            position: absolute;
            top: 0;
            right: 24px;
            height: 100%;
            display: flex;
            align-items: center;
            white-space: nowrap;
        }
        span.right-item {
            font-size: 14px;
            color: #ffffff;
            margin-left: 30px;
            margin-right: 30px;
            cursor: pointer;

            &:last-child {
                margin-right: 0px;
            }
        }
        span.user-box {
            cursor: pointer;
            color: #ffffff;
            font-size: 14px;
        }
        .el-dropdown-link {
            cursor: pointer;
        }
        .el-dropdown {
            color: #ffffff;
        }
    }

    .client-header-main {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        img {
            width: 313px;
            height: 40.14px;
        }
    }
}

</style>
